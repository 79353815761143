<template>
  <div>
    <div v-if="isLoading">
      <aom-skeleton-loader />
    </div>
    <validation-observer
      v-else
      ref="observer"
      v-slot="{ invalid, handleSubmit }"
    >
      <aom-languages>
        <!-- Resource Type -->
        <b-row class="mt-1">
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="resourceType"
              :rules="{ required: true }"
              vid="resourceType"
              name="Resource Type"
            >
              <b-form-group
                label="Resource Type"
                label-for="resourceType"
              >
                <div>
                  <v-select
                    v-model="selectedType"
                    :disabled="isLibraryResource && isProgramResource"
                    label="text"
                    :options="resourceTypesSelect"
                    placeholder="Select the Resource Type"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col md="12">
            <!-- File -->
            <template v-if="showFileInput">
              <b-form-group
                label="Add File (Allowed file extensions .doc, .docx, .docm, .dotx, .txt, .xls, .xlsx, .csv, .xlt, .xlsm, .ppt, .pptx, .pptm, .m4a, .wav, .aac, .mp3, .rar, .zip, .pdf, .png, .jpg, .gif, .bmp, .tif, .mp4, .avi, .mpg)"
                label-for="type"
                class="mb-0"
              >
                <b-form-file
                  ref="refInputEl"
                  v-model="blogFile"
                  :disabled="isLibraryResource && isProgramResource"
                  :state="Boolean(blogFile)"
                  placeholder="Add file"
                  :accept="resourceMimeTypes"
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
              <p
                v-if="resource.translations[0].original_filename"
                class="mb-1"
              >
                Old file: {{ resource.translations[0].original_filename }} (<b-link @click="downloadResource">
                  Download
                </b-link>)
              </p>
            </template>

            <!-- Link -->
            <validation-provider
              v-if="showURLInput"
              v-slot="validationContext"
              ref="link"
              :rules="{ required: true, url_with_protocol: true }"
              vid="link"
              name="URL"
            >
              <b-form-group
                v-if="showURLInput"
                label="URL"
                label-for="link"
              >
                <b-form-input
                  id="title"
                  v-model="link"
                  :disabled="isLibraryResource && isProgramResource"
                  placeholder="https://"
                  :state="getValidationState(validationContext)"
                  :aria-invalid="
                    getValidationState(validationContext)
                      ? 'true'
                      : 'false'
                  "
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Web page -->
            <validation-provider
              v-if="showWebPageInput"
              v-slot="validationContext"
              ref="page_title"
              :rules="{ required: true }"
              vid="page_title"
              name="Page Title"
            >
              <b-form-group
                label="Page Title"
                label-for="page_title"
              >
                <b-form-input
                  id="page_title"
                  v-model="page_title"
                  placeholder=""
                  :state="getValidationState(validationContext)"
                  :aria-invalid="
                    getValidationState(validationContext)
                      ? 'true'
                      : 'false'
                  "
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-if="showWebPageInput"
              v-slot="validationContext"
              :ref="`page_content`"
              rules="required"
              vid="page_content"
              name="Page Content"
            >
              <h5>Page Content</h5>
              <aom-text-editor
                v-if="shouldShowTextEditor"
                v-model="page_content"
                :state="getValidationState(validationContext)"
                :height="100"
                :tool-bar="toolBar"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Title -->
        <b-row class="mt-2">
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="title"
              :rules="{ required: true }"
              vid="title"
              name="Title"
            >
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="title"
                  :disabled="isLibraryResource && isProgramResource"
                  placeholder="Title"
                  :state="getValidationState(validationContext)"
                  :aria-invalid="
                    !getValidationState(validationContext)
                      ? 'true'
                      : 'false'
                  "
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Category -->
        <b-row class="mt-2">
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="category_resource"
              :rules="{ required: true }"
              vid="category_resource"
              name="Category"
            >
              <b-form-group
                label="Category"
                label-for="category_resource"
              >
                <v-select
                  v-model="selectedCategory"
                  :disabled="isLibraryResource && isProgramResource"
                  label="text"
                  :options="categoriesDisplay"
                  placeholder="Select the Categories"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-form-group

            label="Select type of filter to use"
            label-for="recipient"
            class="mb-0"
          >
            <v-select
              v-model="userFilterType"
              label="name"
              :disabled="isLibraryResource"
              :options="filterOptions"
              placeholder="Select a type of recipient"  
             
            >
            <template #option="{ name }">
                <b-row>
                  <b-col sm="12">
                    <span class="mr-1"> {{ name }}</span>
                    
                  </b-col>
                </b-row>
              </template>
          
          </v-select>
        </b-form-group>

        <!-- Group-->

        <validation-provider
          v-slot="validationContext"
          :is-required="userFilterType.name === 'Group' ? true : false" 
          vid="recipient"
          v-if="userFilterType.name === 'Group'"
          name="Recipient"
        >
          <b-form-group
            label="Recipient"
            label-for="recipient"
            class="mb-0 mt-2"
          >
            <v-select
              v-model="recipientGroup"
              label="name"
              :options="programUserGroups"
              :loading="isLoadingGroups"
              placeholder="Select a Group"
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              <template #option="{ name, type }">
                <b-row>
                  <b-col sm="12">
                    <span class="mr-1"> {{ name }}</span>
                    <b-badge
                      variant="secondary"
                    >
                      {{ type }}
                    </b-badge>
                  </b-col>
                </b-row>
              </template>

              <template #selected-option="{ avatar, name }">
                <b-avatar
                  size="sm"
                  class="border border-white"
                  :src="avatar"
                />
                <span class="ml-50"> {{ name }}</span>
              </template>
            </v-select>
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Role -->
        <b-row
          v-if="showUserRoleInput && userFilterType.name === 'Roles'"
          class="mt-2"
        >
          <b-col md="12">
            <user-roles-select
              v-model="selectedUserRole"
              :is-required="userFilterType.name === 'Roles' ? true : false" 
              :list-type="userRolesListType"
              :read-only="isLibraryResource && isProgramResource"
              @update="updateSelectedUserRole"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <b-form-group
              label="Visible to public"
              label-for="isPublic"
            >
              <b-form-checkbox
                v-model="isPublic"
                :disabled="isLibraryResource && isProgramResource"
                :checked="isPublic"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Available -->
        <template v-if="showAvailableSection">
          <b-row class="mt-2">
            <b-col md="3">
              <label class="mt-1">Available</label>
            </b-col>
          </b-row>
          <b-row>
            <!-- When -->
            <b-col
              md="12"
              lg="4"
            >
              <validation-provider
                v-slot="validationContext"
                rules="required"
                vid="show-when"
                name="When"
              >
                <b-form-group label-for="show">
                  <v-select
                    id="show-when"
                    v-model="happenWhen"
                    label="name"
                    placeholder="When"
                    :options="eventTriggersDisplay"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Event -->
            <b-col
              md="12"
              lg="8"
            >
              <validation-provider
                v-slot="validationContext"
                rules="required"
                vid="show-actionable-event"
                name="Event"
              >
                <b-form-group label-for="show">
                  <aom-events-select
                    id="show-actionable-event"
                    v-model="actionableEvent"
                    :options="eventsDisplay"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Custom date -->
              <b-row v-if="showCustomDateField">
                <b-col md="12">
                  <aom-date-picker
                    v-model="scheduledDate"
                    :min="minCustomDate"
                  />
                </b-col>
              </b-row>
            </b-col>

            <!-- Period -->
            <b-col
              v-if="showPeriodField"
              md="12"
              lg="6"
            >
              <validation-provider
                v-slot="validationContext"
                vid="time"
                :rules="{ required: true }"
                name="Period"
              >
                <b-form-group label-for="show">
                  <v-select
                    id="time"
                    v-model="period"
                    label="#"
                    placeholder="Period"
                    :options="periodList"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Duration -->
            <b-col
              v-if="showDurationField"
              md="12"
              lg="6"
            >
              <validation-provider
                v-slot="validationContext"
                vid="duration"
                :rules="{ required: true }"
                name="Duration"
              >
                <b-form-group label-for="show">
                  <v-select
                    id="duration"
                    v-model="duration"
                    label="name"
                    placeholder="Duration"
                    :options="durationUnitsDisplay"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </template>

        <!-- submit and reset -->
        <b-row class="mt-2">
          <b-col cols="12">
            <b-button
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              @click="onReset()"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="invalid"
              @click="handleSubmit(updateResource)"
            >
              <b-spinner
                v-if="isUpdating" 
                small
              />
              <span
                v-if="isUpdating"
              > Updating...</span>
              <span v-else>Submit</span>
            </b-button>
          </b-col>
        </b-row>
      </aom-languages>
    </validation-observer>
  </div>
</template>

<script>
// eslint-disable-next-line
import { mapGetters } from "vuex";
import {
  BRow,
  BAvatar,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BBadge,
  BFormFile,
  BSpinner,
  BLink,
  BFormInvalidFeedback,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import AomLanguages from "@aom-core/AomLanguages";
import UserRolesSelect from "@/@aom-core/UserRolesSelect.vue";
import resourcesService from "@/services/resourcesService";
import groupsService from "@/services/groupsService";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { makeSuccessToast, makeErrorToast, convertModelToFormData } from "@/libs/utils";
import { BE_API_URL } from "@/constants/app";
//eslint-disable-next-line
import { required, url_with_protocol } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { getValidationState, convertStringToDateIgnoreTimezone } from "@/libs/utils";
import {
  resourceMimeTypes,
  resourceTypes,
  resourceTypesDisplay,
  resourceTypesSelect,
  userRoles,
  userRolesDisplay,
  actions,
  eventTriggers,
  eventTriggersDisplay,
  actionableEvent as defaultEvents,
  durationUnitsDisplay,
} from '@models';
import useActionableEvent from "@/views/apps/useActionableEvent.js";
import AomEventsSelect from '@/@aom-core/AomEventsSelect.vue';
import AomDatePicker from "@aom-core/AomDatePicker";
import AomTextEditor from "@aom-core/AoMTextEditor.vue";
import { AOM_TEXT_EDITOR_TOOLBAR_WITHOUT_MERGE_TAGS } from '@/constants/aom-text-editor';

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BFormInput,
    BAvatar,
    BButton,
    BFormFile,
    vSelect,
    UserRolesSelect,
    AomLanguages,
    AomSkeletonLoader,
    BSpinner,
    BLink,
    BFormInvalidFeedback,
    AomEventsSelect,
    AomDatePicker,
    ValidationProvider,
    ValidationObserver,
    AomTextEditor,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  props: {
    isChampionLibrary: {
      type: Boolean
    },
    isProgramResource: {
      type: Boolean,
    },
    resourceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLibraryResource: false,
      dir: "ltr",
      blogFile: null,
      selectedType: null,
      selectedLanguage: [
        {
          value: 1,
          name: "English",
          abbreviation: "eng",
        },
      ],
      selectedCategory: [],
      selectedUserRole: [],
      resourceTypesSelect,
      resource: null,
      isLoading: false,
      isUpdating: false,
      isPublic: false,
      categoriesDisplay: [],
      title: null,
      translationId: null,
      eventTriggersDisplay,
      durationUnitsDisplay,
      periodList: Array.from({length: 100}, (_, i) => i + 1),
      page_title: "",
      page_content: "",
      shouldShowTextEditor: true,
      toolBar: AOM_TEXT_EDITOR_TOOLBAR_WITHOUT_MERGE_TAGS,
      isLoadingGroups: false,
      recipientGroup: null,
      programUserGroups: [],
      userFilterType: {name: 'Roles'}

    };
  },
  computed: {
    filterOptions() {
      return this.isProgramResource && !this.isChampionLibrary ? [{ name: 'Group'}, { name: 'Roles'}] : [{ name: 'Roles'}];
    },
    userRolesListType() {
      if (this.isProgramResource) {
        return 'participant';
      }
      return 'noAdmin';
    },
    showUserRoleInput() {
      return !this.isChampionLibrary;
    },
    showFileInput() {
      return this.selectedType && this.selectedType.value[0] === resourceTypes.FILE;
    },
    showURLInput() {
      return this.selectedType && this.selectedType.value[0] === resourceTypes.URL;
    },
    showWebPageInput() {
      return this.selectedType && this.selectedType.value[0] === resourceTypes.WEB_PAGE;
    },
    showAvailableSection() {
      if (this.selectedUserRole && this.selectedUserRole.length > 0) {
        return !this.selectedUserRole.find(r => r.id === userRoles.CHAMPION);
      }
      return true;
    }
  },
  watch: {
    resourceId: {
      handler() {
        if (this.resourceId) {
          this.fetchResource();
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.isProgramResource && this.getGroups();
    await this.fetchResourceCategoryList();
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === 'edit-resource-right') {
        this.shouldShowTextEditor = isJustShown;
      }
    });
  },
  methods: {
    async resolveService() {
      const programId = this.$route.params.id;
      if (this.isProgramTemplate) {
        return groupsService.getListTemplates(programId);
      }
      return groupsService.getList(programId);
    },
    async getGroups() {
      try {
        this.isLoadingGroups = true;
        const response = await this.resolveService();
        const {items} = response.data;
        this.programUserGroups = items.filter(group => !group.is_champion).map(i => ({id: i.id, name: i.name, type: i.type.name}));
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Groups list not loaded."));
      } finally {
        this.isLoadingGroups = false;
      }
    },
    async fetchResource() {
      try {
        this.isLoading = true;
        const response = this.isProgramResource ?
          await resourcesService.getProgramResourceById(
            this.$route.params.id,
            this.resourceId
          ) :
          await resourcesService.getLibraryResourceById(
            this.resourceId
          );
        // set value to form
        const data = response.data;
        this.resource = data;
        this.isLibraryResource = data.is_library;
        this.isPublic = data.is_public;

        this.translationId = data.translations[0].id;
        this.title = data.translations[0].title;
        this.link = data.translations[0].link;
        this.page_title = data.translations[0].page_title;
        this.page_content = data.translations[0].page_content;
        this.recipientGroup = data.group_id ? this.programUserGroups.find(group => group.id === data.group_id) : null;
        this.selectedType = {
          text: resourceTypesDisplay[data.type_id],
          value: [data.type_id],
        };
        this.userFilterType = data.group_id ? {name: 'Group'} : {name: 'Roles'};
        this.selectedUserRole = data.roles.map(role => ({
          id: role.id,
          name: userRolesDisplay[role.id],
        }));
        this.selectedCategory = this.categoriesDisplay.length > 0 ? {
          text: this.categoriesDisplay.find(
            category => Number(category.value) === Number(data.category_id)
          ).text,
          value: [data.category_id],
        } : [];

        // Parse scheduled action
        if (data.scheduled_action) {
          const scheduledAction = data.scheduled_action;

          if (!scheduledAction.relative_value) {
            this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.ON);
          } else {
            if (scheduledAction.relative_value < 0) {
              this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.BEFORE);
            } else {
              this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.AFTER);
            }
          }

          this.$nextTick(() => {
            this.actionableEvent = scheduledAction.absolute_date
              ? { id: defaultEvents.CUSTOM_DATE, name: 'Custom Date' }
              : this.eventsDisplay.filter(a => a.id === scheduledAction.actionable_event_id).shift();
            this.period = Math.abs(scheduledAction.relative_value);
            this.duration = durationUnitsDisplay.filter(d => d.id === scheduledAction.relative_unit_id)[0];
            this.scheduledDate = scheduledAction.absolute_date ? convertStringToDateIgnoreTimezone(scheduledAction.absolute_date) : undefined;
          });
        }
      } catch (e) {
        this.$toast(makeErrorToast(`Unable to fetch Program Resource ${this.resourceId}.`));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    getScheduledActions() {
      const scheduledAction = {
        action_id: actions.SHOW
      };

      let newPeriod = 0;
      let newDuration = this.duration?.id;
      // Convert relative_value
      if (this.happenWhen) {
        switch (this.happenWhen.id) {
        case eventTriggers.BEFORE:
          newPeriod = -this.period;
          break;
        case eventTriggers.ON:
          newPeriod = 0;
          newDuration = undefined;
          break;
        case eventTriggers.AFTER:
          newPeriod = this.period;
          break;
        }
      }

      // Assign values to scheduledAction
      // Custom date
      if (this.actionableEvent.id === defaultEvents.CUSTOM_DATE && this.scheduledDate) {
        scheduledAction.absolute_date = this.scheduledDate;
      } else {
        scheduledAction.actionable_event_id = this.actionableEvent.id;
      }
      if (newPeriod) {
        scheduledAction.relative_value = newPeriod;
      }
      if (newDuration) {
        scheduledAction.relative_unit_id = newDuration;
      }

      return [scheduledAction];
    },
    async updateResource() {
      this.isUpdating = true;
      try {
        const translation = {
          locale_id: this.selectedLanguage[0].value,
          title: this.title,
          id: this.resource.translations[0].id,
        };
        if (this.selectedType.value[0] === resourceTypes.FILE) {
          translation.file = this.blogFile;
        }
        if (this.selectedType.value[0] === resourceTypes.URL) {
          translation.link = this.link;
        }
        if (this.selectedType.value[0] === resourceTypes.WEB_PAGE) {
          translation.page_title = this.page_title;
          translation.page_content = this.page_content;
        }
        let payload = {
          id: this.resourceId,
          type_id: this.selectedType.value[0],
          category_id: this.selectedCategory.value[0],
          roles: this.selectedUserRole.map(role => ({ id: role.id })),
          translations: [translation],
          is_public: this.isPublic? '1': '0',
          roles: this.userFilterType.name === 'Roles' ? this.selectedUserRole.map(role => ({ id: role.id })) : null,
          group_id: this.userFilterType.name === 'Group' ?  this.recipientGroup.id : null,
        };
        // Add scheduled_actions to payload for normal resource
        if (this.showAvailableSection) {
          payload.scheduled_actions = this.getScheduledActions();
        }
        if (this.resource.type_id === resourceTypes.FILE && payload.type_id === resourceTypes.FILE && !this.blogFile) {
          delete payload.type_id;
        }
        if (this.isLibraryResource && this.isProgramResource) {
          payload = {
            id: this.resourceId,
            scheduled_actions: payload.scheduled_actions
          };
        }
        const response = this.isProgramResource ?
          await resourcesService.updateProgramResource(
            this.$route.params.id,
            this.resourceId,
            convertModelToFormData({...payload, _method: 'PATCH'})
          ) :
          await resourcesService.updateLibraryResource(
            this.resourceId,
            convertModelToFormData({...payload, _method: 'PATCH'})
          );

        if (response) {
          this.$emit("resourceUpdated");
          this.$toast(makeSuccessToast("Resource updated successfully."));
          this.$root.$emit("bv::toggle::collapse", "edit-resource-right");
        }
      } catch (e) {
        console.log(e);
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    onReset() {
      this.selectedUserRole = [];
      this.selectedCategory = [];
      this.$root.$emit("bv::toggle::collapse", "edit-resource-right");
    },
    updateSelectedUserRole(role) {
      this.selectedUserRole = role;
    },
    async fetchResourceCategoryList() {
      try {
        this.isLoading = true;
        const response = this.isProgramResource? await resourcesService.getProgramResourceCategoriesList(this.$route.params.id): await resourcesService.getResourceCategoriesList();
        if (response) {
          this.categoriesDisplay = response.data.items.map(resourceCategory => (
            {
              value: [resourceCategory.id],
              text: resourceCategory.name
            }
          ));
        }
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Resource category not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    downloadResource() {
      const programId = this.$route.params.id;
      const id = this.resourceId;
      const translationId = this.translationId;

      let dowloadLink = `${BE_API_URL}/library/resources/${id}/translations/${translationId}/download`;
      if (this.isProgramResource) {
        dowloadLink = `${BE_API_URL}/programs/${programId}/resources/${id}/translations/${translationId}/download`;
      }
      window.document.location = dowloadLink;
    },
  },
  setup(props) {
    const {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      eventsDisplay,
      minCustomDate
    } = useActionableEvent(props.isProgramResource);

    return {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      getValidationState,
      eventsDisplay,
      resourceMimeTypes,
      minCustomDate
    };
  }
};
</script>
